<template>
  <div id="app" style=" width: 100%;">
    <div class="header" ref="nav" style="min-height:90px;">
      <el-row style="width: 100%; display: flex; align-items: center; justify-content: center;">
        <el-col :xs="15" :sm="10" :md="12" :lg="10" :xl="10">
          <img id="header-img" src="../assets/logo2.png" style="width:200px;" />
        <!-- <img id="header-img" src="../assets/blue.png" style="width:220px; margin-left: 4em;" class="ximg" v-if="hidden" /> -->
        </el-col>
        <!-- <p>{{ screenHeight }}</p> -->
        <el-col :xs="10" :sm="12" :md="12" :lg="12" :xl="8" v-if="seen">
          <el-menu  :default-active="activeIndex" class="el-menu-demo" menu-trigger="click" text-color="#333"
            style="height: 65px; border: 0" mode="horizontal" @select="handleSelect">
            <el-menu-item index="1" class="header-title">首页</el-menu-item>
            <el-menu-item index="1-1" class="header-title">关于我们</el-menu-item>
            <el-menu-item index="2" class="header-title">解决方案</el-menu-item>
            <el-menu-item index="3" class="header-title">产品服务</el-menu-item>
            <el-menu-item index="4" class="header-title">合作伙伴</el-menu-item>
            <el-menu-item index="5" class="header-title">联系我们</el-menu-item>
          </el-menu>
        </el-col>
        <el-col :xs="5" :sm="6" :md="8" :lg="9" :xl="8" v-if="seen2">
          <el-menu  :default-active="activeIndex" class="el-menu-demo" menu-trigger="click" text-color="#333"
            style="height: 65px; border: 0" mode="horizontal" @select="handleSelectMobile">
            <el-submenu index="1" class="header-title mobile_menu">
              <template slot="title">{{ currentMenu }}</template>
              <el-menu-item index="1" class="header-title">首页</el-menu-item>
              <el-menu-item index="1-1" class="header-title">关于我们</el-menu-item>
              <el-menu-item index="2-2" class="header-title">解决方案</el-menu-item>
              <el-menu-item index="3" class="header-title">服务产品</el-menu-item>
              <el-menu-item index="4" class="header-title">合作伙伴</el-menu-item>
              <el-menu-item index="5" class="header-title">联系我们</el-menu-item>
            </el-submenu>
          </el-menu>
        </el-col>
      </el-row>




















      <!-- <div class="logo_img" @click="logoClick">
        <img id="header-img" src="../assets/logo2.png" style="width:200px;" />
      </div> -->
      <!-- <div id="menu_index">
        <el-menu v-show="seen" :default-active="activeIndex" class="el-menu-demo" menu-trigger="click" text-color="#333"
          style="height: 65px; border: 0" mode="horizontal" @select="handleSelect">
          <el-menu-item index="1" class="header-title">首页</el-menu-item>
          <el-menu-item index="1-1" class="header-title">关于我们</el-menu-item>
          <el-menu-item index="2" class="header-title">解决方案</el-menu-item>
          <el-menu-item index="3" class="header-title">产品服务</el-menu-item>
          <el-menu-item index="4" class="header-title">合作伙伴</el-menu-item>
          <el-menu-item index="5" class="header-title">联系我们</el-menu-item>
        </el-menu>
        <el-menu v-show="!seen" :default-active="activeIndex" class="el-menu-demo" menu-trigger="click" text-color="#333"
          style="height: 65px; border: 0" mode="horizontal" @select="handleSelectMobile">
          <el-submenu index="1" class="header-title mobile_menu">
            <template slot="title">{{ currentMenu }}</template>
            <el-menu-item index="1" class="header-title">首页</el-menu-item>
            <el-menu-item index="1-1" class="header-title">关于我们</el-menu-item>
            <el-menu-item index="2-2" class="header-title">解决方案</el-menu-item>
            <el-menu-item index="3" class="header-title">服务产品</el-menu-item>
            <el-menu-item index="4" class="header-title">合作伙伴</el-menu-item>
            <el-menu-item index="5" class="header-title">联系我们</el-menu-item>
          </el-submenu>
        </el-menu>
      </div> -->
    </div>
    <!-- <div style="width: 100%; height: 65px"></div> -->
    <div class="container" style="clear: both">
      <router-view></router-view>
    </div>
    <!-- <div id="back_to_top" ref="btn" @click="backTop" style="display: none">
        <p style="font-size: 0.625em; font-weight: bold">TOP</p>
        <img src="../assets/other/launch.png" style="height: 45px; width: 35px" />
      </div> -->
  </div>
</template>
<script>
export default {
  name: "Layout",
  data() {
    return {
      activeIndex: "1",
      company: {
        qrcode: require("../assets/other/公众号二维码.jpg"),
      },
      scroll: "",
      timer: "",
      seen: false,
      seen2: false,
      currentMenu: "首页",
      screenHeight:"",
      hidden:true,
      screenWidth: window.innerWidth,
    };
  },
  beforeCreate() {
    
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll); // 监听滚动事件
    window.addEventListener('resize', this.handleResize); // 监听窗口大小变化事件

  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll); // 移除滚动事件监听
    window.removeEventListener('resize', this.handleResize); // 移除窗口大小变化事件监听

  },
  methods: {
    handleSelect(key) {
      switch (key) {
        case "1":
          this.$router.push({ name: "Index" });

          break;
        case "1-1":
          this.$router.push({ name: "/AboutUs" });
          break;
        case "2":
          this.$router.push({ name: "/solution" });
          break;
        case "2-2":
          this.$router.push({ name: "/solution2" });
          break;
        case "2-3":
          this.$router.push({ name: "/solution3" });
          break;
        case "3":
          this.$router.push({ name: "/service" });
          break;
        case "3-2":
          this.$router.push({ name: "/vip" });
          break;
        case "4":
          this.$router.push({ name: "/partner" });
          break;
        case "5":
          this.$router.push({ name: "/ContactUs" });
          break;
      }
    },
    handleSelectMobile(key) {
      switch (key) {
        case "1":
          this.$router.push({ name: "Index" });
          break;
        case "1-1":
          this.$router.push({ name: "/AboutUs" });
          break;
        case "2-2":
          this.$router.push({ name: "/solution" });
          break;
        // case "2-2":
        // this.$router.push({ name: "/solution2" });
        //   break;
        case "2-3":
          this.$router.push({ name: "/solution3" });
          break;
        case "3":
          this.$router.push({ name: "/service" });
          break;
        case "3-2":
          this.$router.push({ name: "/vip" });
          break;
        case "4":
          this.$router.push({ name: "/partner" });
          break;
        case "5":
          this.$router.push({ name: "/ContactUs" });
          break;
      }
    },
    handleScroll() {
      this.scroll =
        document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
        // console.log(this.scroll);
      if (this.scroll > 2) {
        //判断滚动后高度超过400px,就显示
        // this.$refs.btn.style.display = "block";
        this.$refs.nav.style.position='fixed'
      } else {
        // this.$refs.btn.style.display = "none";
        this.$refs.nav.style.position='relative'
      }
    },
    handleResize() {
      this.screenWidth = window.innerWidth; // 更新屏幕宽度
      if (this.screenWidth > 950) {
        this.seen = true;
        this.seen2 = false;
        this.hidden=true
      } else {
        this.seen = false;
        this.seen2 = true;
        this.hidden=false
      }
    },
    backTop() {
      //当点击标签的时候,使用animate在200毫秒的时间内,滚到顶部
      this.timer = setInterval(() => {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        if (osTop === 0) {
          clearInterval(this.timer);
        }
      }, 30);
    },
    login() {
      window.open("https://admin.fastice-tech.com/login");
    },
    register() {
      window.open("https://admin.fastice-tech.com/registe");
    },
    logoClick() {
      this.$router.push({ name: "Index" });
      this.currentMenu = "首页";
    },
  },
  created() {
    this.screenHeight = document.body.clientWidth;
    // console.log(this.screenHeight);
      if (this.screenHeight > 600) {
        this.seen = true;
        this.seen2 = false;
        this.hidden=true
      } else {
        this.seen = false;
        this.seen2 = true;
        this.hidden=false
      }
  },
};
</script>
  
<style scoped>
/* @font-face {
  font-family: 'Siyuan Black';
  src: url('../font/字体圈伟君黑-W1.ttf') format('woff2')
}

#app {
  font-family: 'Siyuan Black'
} */

.x1 {
  /* border-bottom: 1px solid red !important; */
}

/* #app {
      -webkit-filter:grayscale(100%);
      -moz-filter:grayscale(100%);
      -ms-filter:grayscale(100%);
      -o-filter:grayscale(100%);
      filter:grayscale(100%);
  } */
.logo_img {
  /* float:left;
          width: 30%;
          cursor: pointer; */
}

#back_to_top {
  position: fixed;
  bottom: 50px;
  right: 30px;
  cursor: pointer;
}

.header-title {
  font-size: 14px;
}

.company_name {
  font-weight: normal;
  font-size: 14px;
}

#menu_index {
  /* float: left; width: 50%;height: 100% */
}

.header {
  width: 100%;
  /* position: fixed; */
  z-index: 9999;
  background: white;
  box-shadow: 1px 1px 5px 1px #ccc;
  display: flex;
  justify-content: center;
}

.header-logo {
  /* height: 50px; */
  width: 50px;
  align-self: center;
}

.header-important {
  float: left;
  margin-left: 6px;
  align-self: center;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80vw;
  height: 300px;
  /* background: red; */
  margin: 0 auto;
  /* background: #15aec4; */
}

a {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

/* .el-menu--horizontal>.el-menu-item.is-active{
    border: transparent;
  } */
</style>