<template>
  <div id="app" style="">
    <!-- <div style="width: 100%; height:100px;"></div> -->
    <div>
      <div class="footer">
        <img src="../assets/logo2.png" alt="" style="width:170px;" />
        <div class="text">
          <div style="width: 30%" class="percent">
            <p>
              <!-- <span>德行天下 安康于世</span> -->
            </p>
            <p>北京德安健康管理有限公司</p>
            <p>电话:400-602-9998</p>
            <!-- <p>地址:北京市朝阳区望京顺北大街诚盈中心12G</p> -->
          </div>
          <div style="width: 70%" class="percent">
            <p>京ICP备2021005988号-1</p>
            <p>统一社会信用代码:91110105MA0071EX7F</p>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Layout",
  data() {
    return {
      activeIndex: "1",
      company: {
        // qrcode: require("../../static/other/公众号二维码.jpg"),
      },
      scroll: "",
      timer: "",
      seen: true,
      currentMenu: "首页",
    };
  },
  methods: {
    menuSeen() {
      // 屏幕尺寸
      let screenHeight = document.body.clientWidth;
      if (screenHeight > 600) {
        this.seen = true;
      } else {
        this.seen = false;
      }
    },
    logoClick() {
      this.$router.push({ name: "Index" });
      this.currentMenu = "首页";
    },
  },
  mounted() {
    this.menuSeen();
  },
};
</script>
<style scoped>
.white {
  height: 200px;
}

@media screen and (max-device-width: 600px) {
  .footer {
    font-size: 12px !important;
    height: auto !important;
    padding-bottom: 25px;
    /* flex-direction: column; */
  }

  .footer img {
    width: 100px;
  }

  .footer div {
    /* width: 50% !important; */
  }

  .white {
    height: 100px !important;
  }
  .text {
    display: flex;
    /* align-items: center; */
    /* justify-content: space-around; */
    flex-direction: column !important;
  }
  .percent{
    width: 100% !important;
  }
}
.footer{
  width: 89vw;
  margin: 0 auto;
  font-size: 16px;
  background-color: #f2f6fc;
  padding-left: 10vw;
  padding-top: 5vh;
}
.text {
  display: flex;
  /* align-items: center; */
  justify-content: space-around;
}

a {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}</style>