<template>
    <div>
        <!-- <Header></Header> -->
        <!-- <el-carousel :height="computeHeight">
            <el-carousel-item v-for="(item, index) in sliders" :key="index">
                <img :src="item.img" class="slider_img">
            </el-carousel-item>
        </el-carousel> -->
        <div class="banner" style="width:100%; height:auto; text-align: center;">
            <img :src="Src_" alt="" style="width:100%; height:100%;">
        </div>
        <div style="text-align:center;" class="Ci">
            <p style="font-size:34px;" class="cp1">德安 - 数智化综合健康管理服务平台</p>
            <p style="font-size:30px;" class="cp2">
                <span>德安健康为银行、保险、企业、家庭和个人提供数智化医疗和健康服务，为客户提供全面贴心的健康服务及机构权益福利保障计划。</span>
            </p>
        </div>
        <div class="ht">
            <!-- <p>德行天下 安康于世</p> -->
            <!-- <img src="../assets/87967e3b7c1b56e0d34f40a605b7c31.png" alt=""> -->
            <div>
                <p>
                    <span>为什么今年支原体传染严重？“支原体肺炎”究竟是啥？</span>
                    <span>近期，我国多个地区出现了支原体肺炎的流行，尤其是在南方地区，与去年同期相比上升明显。支原体肺炎是由肺炎支原体引起的急性肺部感染性疾病，是我国5岁及以上儿童最主要的社区获得性肺炎。（社区获得性肺炎是指医院外获得的肺炎，也包括入院后48小时内肺内出现的炎症病灶。）那么，为什么今年支原体传染严重？又该如何预防和治疗呢？</span>
                    <span style="text-align:right;"><a href="https://mp.weixin.qq.com/s/WDivH9Jd4F4R-BZQs5YKlw" target='_blank' style="color:white;" >查看详情</a></span>
                </p>
            </div>
        </div>
        <div class="solve head-label" style=" padding: 50px;">
            <div style="text-align: center; font-size:34px;" class="font_size_24">
                德安健康业务优势
            </div>
        </div>
        <!-- 盒子 -->
        <div class="border_parent">
            <el-row :gutter="10">
                <el-col :xs="24" :sm="12" :md="6">
                    <div class="border">
                        <div class="border_img_parent">
                            <img :src="images[1].img" class="img_border" />
                        </div>
                        <div class="border_title">{{ images[1].title }}</div>
                        <div class="border_content_one">{{ images[1].content[0] }}</div>
                        <div class="border_content_two">{{ images[1].content[1] }}</div>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="12" :md="6">
                    <div class="border">
                        <div class="border_img_parent">
                            <img :src="images[2].img" class="img_border" />
                        </div>
                        <div class="border_title">{{ images[2].title }}</div>
                        <div class="border_content_one">{{ images[2].content[0] }}</div>
                        <div class="border_content_two">{{ images[2].content[1] }}</div>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="12" :md="6">
                    <div class="border">
                        <div class="border_img_parent">
                            <img :src="images[4].img" class="img_border" />
                        </div>
                        <div class="border_title">{{ images[4].title }}</div>
                        <div class="border_content_one">{{ images[4].content[0] }}</div>
                        <div class="border_content_two">{{ images[4].content[1] }}</div>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="12" :md="6">
                    <div class="border">
                        <div class="border_img_parent">
                            <img :src="images[3].img" class="img_border" />
                        </div>
                        <div class="border_title">{{ images[3].title }}</div>
                        <div class="border_content_one">{{ images[3].content[0] }}</div>
                        <div class="border_content_two">{{ images[3].content[1] }}</div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <!-- 后台管理 -->
        <div class="product_pc">
            <el-row style="height:auto;">
                <el-col :xs="24" :sm="12" style="height:100%;">
                    <div class="product_pc_one">
                        <div class="product_mobile_title font_size_30">{{ manages[0].title }}</div>
                        <div style="margin-top: 20px" class="product_font_style">{{ manages[0].content[0] }}</div>
                        <div style="margin-top: 10px" class="product_font_style">{{ manages[0].content[1] }}</div>
                        <div style="margin-top: 10px" class="product_font_style">{{ manages[0].content[2] }}</div>
                        <!-- <div style="margin-top: 10px" class="product_font_style">{{manages[0].content[3]}}</div>
                        <div style="margin-top: 10px" class="product_font_style">{{manages[0].content[4]}}</div> -->
                    </div>
                </el-col>
                <el-col :xs="24" :sm="12" style=" height:100%;">
                    <img :src="manages[0].img[0]" class="product_pc_img" />
                </el-col>
            </el-row>
        </div>
        <!-- 产品体系-->
        <div id="产品体系" class="product_spec_title head-label" style="padding:50px;">
            <div style="text-align: center;font-size:34px; " class="font_size_24">
                德安健康产品体系
            </div>
        </div>
        <div class="border_parent">
            <el-row :gutter="10">
                <el-col :xs="24" :sm="12" :md="6">
                    <div class="border">
                        <div class="border_img_parent">
                            <img :src="spec.product[0].img" class="img_border" />
                        </div>
                        <div class="border_title">{{ spec.product[0].title }}</div>
                        <div class="border_content_one">{{ spec.product[0].contents[0] }}</div>
                        <div class="border_content_two">{{ spec.product[0].contents[1] }}</div>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="12" :md="6">
                    <div class="border">
                        <div class="border_img_parent">
                            <img :src="spec.product[1].img" class="img_border" />
                        </div>
                        <div class="border_title">{{ spec.product[1].title }}</div>
                        <div class="border_content_one">{{ spec.product[1].contents[0] }}</div>
                        <div class="border_content_two">{{ spec.product[1].contents[1] }}</div>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="12" :md="6">
                    <div class="border">
                        <div class="border_img_parent">
                            <img :src="spec.product[2].img" class="img_border" />
                        </div>
                        <div class="border_title">{{ spec.product[2].title }}</div>
                        <div class="border_content_one">{{ spec.product[2].contents[0] }}</div>
                        <div class="border_content_two">{{ spec.product[2].contents[1] }}</div>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="12" :md="6">
                    <div class="border">
                        <div class="border_img_parent">
                            <img :src="spec.product[3].img" class="img_border" />
                        </div>
                        <div class="border_title">{{ spec.product[3].title }}</div>
                        <div class="border_content_one">{{ spec.product[3].contents[0] }}</div>
                        <div class="border_content_two">{{ spec.product[3].contents[1] }}</div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div style="width: 100%; height:100px;"></div>
        <Footer></Footer>
    </div>
</template>
<script>
import Header from '../../src/views/Layout.vue'
import Footer from '../../src/views/footer.vue'
export default {
    components: { Header, Footer },
    name: 'Index',
    data() {
        return {
            sliders: [],
            images: [
                {
                    img: require('../assets/other/用户管理.png'),
                    title: "用户管理",
                    content: ["用户消费分析", "用户标签设定"]
                },
                {
                    img: require('../assets/other/场景.jpg'),
                    title: "医疗健康全场景覆盖",
                    content: ["定制体检、专家预约、重疾会诊", "陪诊服务、细胞存储、细胞疗程"]
                },
                {
                    img: require('../assets/other/医生.jpg'),
                    title: "中西医兼备",
                    content: ["中西医两大医疗理论支持", "获得更多预防、保健、治愈机会"]
                }, {
                    img: require('../assets/other/资源.jpg'),
                    title: "优质资源协调",
                    content: ["北京知名医院专家资源", "会诊、转诊及时就医"]
                },
                {
                    img: require('../assets/other/地球.jpg'),
                    title: "全球资源覆盖",
                    content: ["线上+线下", " 瑞士、美国、日本等国家医疗、康养"]
                }
            ],
            products: [
                {
                    img: [require('../assets/other/手机端商品.png'), require('../assets/other/手机端商品SKU.png')],
                    title: "手机端商品展示 满足多规格显示",
                    content: ["商品热销分类显示 分类导航显示所有分类", "热销商品优先排序 商城首页轮播自定义",
                        "商品多规格多价格展示 商品直接在线购买支付"
                    ]
                }
            ],
            manages: [
                {
                    img: [require('../assets/other/index1.png')],
                    title: "H5+小程序全方位支持客户APP集成",
                    content: ["为银行、保险、企业客户提供API接口", "将健康权益直接呈现在客户端", "支持客户定制LOGO、风格，与客户VI保持一致"
                    ]
                }
            ],
            spec: {
                img: require('../assets/other/arrow.png'),
                product: [
                    {
                        img: require('../assets/other/银行.jpg'),
                        title: "银行服务",
                        contents: ["员工健康管理、定制体检套餐", "健康权益服务、积分商城"]
                    },
                    {
                        img: require('../assets/other/保险.jpg'),
                        title: "保险服务",
                        contents: ["定制健康保险，丰富保险产品线", "重疾保障、医疗保障、健康服务"]
                    },
                    {
                        img: require('../assets/other/企业.jpg'),
                        title: "企业服务",
                        contents: ["实际解决健康问题", "提升满意度和幸福感"]
                    },
                    {
                        img: require('../assets/other/会员.jpg'),
                        title: "会员服务",
                        contents: ["个性化健康管理", "签署保密协议"]
                    }
                ]
            },
            company: [
                "北京德安健康管理有限公司（简称德安健康）成立于2016年，公司总部位于北京市朝阳区望京顺北大街诚盈中心，德安健康致力于成为中国领先的健康管理综合服务平台。",
                "公司拥有深厚的医疗专家资源和健康机构服务资源，致力于打造包括高端健康管理、高端就医在内的全生命周期的健康管理服务平台，为企业、机构、家庭和个人提供一站式医疗和健康管理服务。",
                "德安健康私人健康医疗服务，以北京为中心，在全国主要城市和海外建立起全面专业的医疗专家网络并积累20余年成功服务高端客户的经验，致力为客户提供全面的医疗保健服务和优越的顾问式医疗服务体验。",
                "德安健康为金融机构提供贵宾客户增值服务，目前已为中国银行、中信银行、民生银行、兴业银行、渤海银行、人保健康、人保寿险、君康人寿、亚太财产等金融机构提供健康管理服务，得到广大金融机构客户的高度认可。",
            ],
            carousel_height: '550px',
        }
    },
    methods: {},
    mounted() {
        this.sliders = [
            {
                img: require("../assets/other/1.jpg")
            },
            {
                img: require("../assets/other/2.jpg")
            },
            {
                img: require('../assets/other/3.jpg')
            },
            // {
            //     img: require('../assets/slider/a4.jpg')
            // }
        ]
        const elements = document.querySelectorAll('.border');
        const elements2 = document.querySelectorAll('.border_spec');

        // 为每个元素添加鼠标划入事件监听器

        elements.forEach(element => {
            element.addEventListener('mouseenter', function () {
                // 在这里修改当前划入的元素的样式

                this.style.transform = 'scale(1.1)';
                this.style.transition = '.5s';
            });
            element.addEventListener('mouseleave', function () {
                // 在这里恢复当前划出的元素的样式

                this.style.transform = 'scale(1)';
                this.style.transition = '.5s';
            });
        });
        elements2.forEach(element => {
            element.addEventListener('mouseenter', function () {
                // 在这里修改当前划入的元素的样式

                this.style.transform = 'scale(1.1)';
                this.style.transition = '.5s';
            });
            element.addEventListener('mouseleave', function () {
                // 在这里恢复当前划出的元素的样式

                this.style.transform = 'scale(1)';
                this.style.transition = '.5s';
            });
        });
    },
    computed: {
        Src_() {
            let w = document.body.clientWidth;
            if (w > 600) {
                return require('../assets/首页banner.png')
            } else {
                return require('../assets/首页banner2.png')
            }
        },
        //计算高度
        computeHeight() {
            let screenHeight = document.body.clientWidth;
            if (screenHeight > 600) {
                return '450px';
            } else {
                return '250px';
            }
        },
        computeArrowImg() {
            let screenHeight = document.body.clientWidth;
            if (screenHeight > 450) {
                return require('../assets/other/arrow.png');
            } else {
                return require('../assets/other/arrow_down.png');
            }
        }

    },
}
</script>

<style scoped>
/* 屏幕设备适配 max-device-width: 400px */
@media screen and (max-width: 800px) {
    .Ci {
        /* padding: 30px 0 !important; */
        padding: 0 !important;
        margin: 20px;
        /* height: 300px; */
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .cp1 {
        font-size: 20px !important;
        padding-top: 0 !important;
    }

    .cp2 {
        font-size: 14px !important;
        padding-bottom: 0 !important;

    }

    .ht {
        margin-top: 20px;
        height: 150px !important;
    }
    .ht div{
        width: 90% !important;
    }
    .ht span{
        font-size: 12px !important;
    }
    .ht img {
        scale: .5 !important;
    }

    .solve,.product_spec_title {
        padding: 20px !important;
    }

    .solve div {
        font-size: 20px !important;
    }

    .border_title {
        margin-top: 10px;
        font-size: 14px !important;
        
        text-align: center;
    }

    .banner {
        height: auto !important;
        margin-bottom: 0 !important;
    }

    .product_pc_img {
        width: 100%;
    }

    .border_content_one {
        margin-top: 20px;
        font-size: 14px !important;
        text-align: center;
    }

    .border_content_two {
        margin-top: 5px;
        font-size: 14px !important;
        margin-bottom: 25px;
        text-align: center;
    }

    .product_spec_title div {
        font-size: 20px !important;
    }

    .product_spec_parent {
        flex-wrap: wrap;
    }

    .border_spec {
        width: 80% !important;
        height: 300px !important;
        padding: 0 5px !important;
        margin: 5px auto !important;
    }

    .font_size_30 {
        font-size: 16px !important;
    }

    .product_font_style {
        font-size: 14px !important;
    }

    .product_pc .el-row {
        display: flex !important;
        flex-direction: column !important;
    }

    .font_size_24 {
        font-size: 16px !important;
    }

    .border_spec_content_one {
        font-size: 14px !important;
    }

    .border_spec_inner_last {
        font-size: 14px !important;
    }

    .border_spec {
        height: 250px !important;
    }

    .product_pc_one {
        margin: 45px !important;
    }
}
.Ci{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
    margin:0 auto;
}
.border_parent {
    width: 90%;
    margin: auto;
}

/* .border :hover {
        transform: scale(1.2);
        transition: .5s;
    } */
.slider_img {
    height: 450px;
    width: 100%;
}

.solve {
    margin-top: 30px;
    margin-bottom: 10px
}

.border {
    border-radius: 6px;
    border: 1px solid #EBEEF5;
    height: auto;
    margin: 10px;
    font-size: 24px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    height: 300px;
}

.ht {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    font-weight: bolder;
    color: white;
    background: #15a0aa;
    width: 100%;
    height: 150px;
    color: white;
}
.ht div{
    width: 60%;
}
.ht div p{
    display: flex;
    flex-direction: column;
}
.ht div p span{
    font-size: 16px;
    margin-top: 5px;
}

.product_pc::v-deep .el-row {
    display: flex;
    align-items: center;
}

.ht img {
    max-width: 100%;
    max-height: 162px;
    scale: .8;
}

.border_img_parent {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.product_mobile_content {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.product_mobile_img {
    width: 50%;
}

.product_mobile_title {
    
    text-align: center;
    font-size: 25px;
}

.product_pc_one {
    /* border: 1px solid red; */
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.font_size_30 {
    font-size: 24px;
}

.product_font_style {
    font-size: 18px;
}

.product_pc_img {
    /* width: 100%; */
    height: 100%;
    margin: 0 auto;
}

.product_pc_img_div {
    width: 50%;
    float: left;
    display: flex;
    align-items: center;
}

.product_pc {
    display: flex;
    width: 90%;
    margin: 20px auto;
    background: #F2F6FC;
    align-items: center;
}

.product_pc .el-row {
    width: 100%;
}

.product_spec_title {
    margin-top: 30px;
    margin-bottom: 10px
}

.product_spec_parent {
    display: flex;
    width: 90%;
    margin: 20px auto;
    align-items: center;
    height: auto
}

.cooperation_parent {
    display: flex;
    justify-content: center;
    width: 90%;
    margin: 20px auto;
    align-items: center;
    height: auto
}

.border_spec {
    border-radius: 6px;
    border: 1px solid #EBEEF5;
    float: left;
    width: 25%;
    height: 250px;
    margin: 10px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.border_title {
    margin-top: 10px;
    font-size: 18px;
    
    text-align: center;
}

.border_content_one {
    margin-top: 20px;
    font-size: 16px;
    text-align: center;
}

.border_content_two {
    margin-top: 5px;
    font-size: 16px;
    margin-bottom: 25px;
    text-align: center;
}

.img_border {
    margin-top: 15px;
    width: 80px;
    height: 80px
}

.border_spec_inner_one {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
    height: 70px;
}

.border_spec_content_one {
    margin-top: 15px;
    font-size: 20px;
    float: left;
    text-align: center;
}

.border_spec_inner_last {
    margin-top: 15px;
    font-size: 20px;
    float: left;
    margin-bottom: 25px;
    text-align: center;
}

.cooperation {
    margin-top: 30px;
    width: 100%;
}

.about_me {
    display: flex;
    margin-top: 30px;
    justify-content: center;
    width: 100%;
}

.product_mobile {
    width: 90%;
    align-items: center;
    margin: 20px auto;
    background: #F2F6FC;
}

.product_mobile_img_div {
    width: 50%;
    height: auto;
}

.product_mobile_content_one {
    margin-top: 20px;
    text-align: center;
}

.product_mobile_content_two {
    margin-top: 10px;
    text-align: center;
}



.border_spec_img {
    width: 75px;
    height: 55px;
    align-self: center;
}

.font_size_24 {
    font-size: 24px;
}</style>