import 'amfe-flexible';
import Vue from 'vue'
import Layout from './views/Layout.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false

Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(Layout)
}).$mount('#app')
