import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/Index',
    name: 'Index',
    component: () => import('../views/Index.vue')
  },
  {
    path: '/AboutUs',
    name: '/AboutUs',
    component: () => import('../views/AboutUs.vue')
  },
  {
    path: '/ContactUs',
    name: '/ContactUs',
    component: () => import('../views/ContactUs.vue')
  },
  {
    path: '/partner',
    name: '/partner',
    component: () => import('../views/partner.vue')
  },
  {
    path: '/service',
    name: '/service',
    component: () => import('../views/service.vue')
  },
  {
    path: '/solution',
    name: '/solution',
    component: () => import('../views/solution.vue')
  },
  // {
  //   path: '/solution2',
  //   name: '/solution2',
  //   component: () => import('../views/solution2.vue')
  // },
  // {
  //   path: '/solution3',
  //   name: '/solution3',
  //   component: () => import('../views/solution3.vue')
  // },
  {
    path: '/vip',
    name: '/vip',
    component: () => import('../views/vip.vue')
  },
  {
    path: '/Layout',
    name: '/Layout',
    component: () => import('../views/Layout.vue')
  },
  {
    path: '/privacy',
    name: '/privacy',
    component: () => import('../views/privacy.vue')
  },
]
const router = new VueRouter({
  routes,
  scrollBehavior: () => ({ y: 0 }), 
})

export default router
